import ReactGA from 'react-ga';
import {
  GET_ADBOX,
  LISTEN_TO_PODCAST,
  SET_NEXT_STEP,
} from './modules/adBox/types';
import { GET_STATIC_BANNER } from './modules/staticBanner/types';

export const googleAnalyticsMiddleware = () => next => (action) => {
  ReactGA.initialize('UA-138887430-1', {
    debug: false,
    titleCase: false,
    gaOptions: {
      cookieFlags: 'SameSite=None; Secure',
    },
  });
  ReactGA.pageview(window.location.pathname + window.location.search);

  switch (action.type) {
    case GET_STATIC_BANNER: {
      ReactGA.event({
        category: 'Banner Static',
        action: 'Show static banner',
      });
      break;
    }
    case GET_ADBOX: {
      ReactGA.event({
        category: 'Banner',
        action: 'Show',
      });
      break;
    }
    case SET_NEXT_STEP: {
      if (action.payload.nextStep === 'three') {
        ReactGA.event({
          category: 'Submit email',
          action: 'SignIn',
        });
      }
      if (action.payload.nextStep === 'four') {
        ReactGA.event({
          category: 'Submit email',
          action: 'SignUp',
        });
      }
      break;
    }
    case LISTEN_TO_PODCAST: {
      ReactGA.event({
        category: 'New Tab Launches',
        action: 'Clicked play button',
      });
      break;
    }
    default: {
      //
    }
  }
  return next(action);
};
