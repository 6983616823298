import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './fonts/stylesheet.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// window.particlesJS.load('background', 'assets/particles.json', function () {
//   console.log('callback - particles.js config loaded');
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
