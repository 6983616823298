import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './themes/theme';
import store from './state/store';
import Root from './pages/Root';

import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  useEffect(() => {
    if (window.opener && window.opener !== window) {
      window.opener.postMessage('success');
      window.close();
    }
  }, []);

  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Route path="/" component={Root} />
          </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </Fragment>
  );
}
