import * as actionTypes from './types';

let initialState = {
  widgets: null,
  filter: [],
  banners: null,
  iHMStats: null,
  iHMPlaysStats: null,
  loading: false,
  loadedSubTable: null,
};

function reducers(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.GET_STATISTIC_WIDGETS:
      return {
        ...state,
        widgets: null,
        loading: true,
      };
    case actionTypes.GET_STATISTIC_WIDGETS_SUCCESS:
      return {
        ...state,
        widgets: action.payload,
        loading: false,
      };
    case actionTypes.GET_STATISTIC_BANNER:
      return {
        ...state,
        banners: null,
        loading: true,
      };
    case actionTypes.GET_STATISTIC_BANNER_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        loading: false,
      };
    case actionTypes.GET_FILTER_SUCCESS:
      return {
        ...state,
        filter: action.payload,
      };
    case actionTypes.GET_STATISTIC_SUBTABLE:
      return {
        ...state,
        loadedSubTable: action.payload.publisherId,
      };
    case actionTypes.GET_STATISTIC_SUBTABLE_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        loadedSubTable: null,
      };
    case actionTypes.GET_STATISTIC_IHM_SUCCESS:
      return {
        ...state,
        iHMStats: action.payload,
      };
    case actionTypes.GET_IHM_PLAYS_SUCCESS:
      return {
        ...state,
        iHMPlaysStats: action.payload,
      };
    case actionTypes.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}

export default reducers;
