export const ADD_BANNER = 'ADD_BANNER';
export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS';
export const ADD_BANNER_FAIL = 'ADD_BANNER_FAIL';

export const GET_BANNERS = 'GET_BANNERS';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAIL = 'GET_BANNERS_FAIL';

export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAIL = 'GET_BANNER_FAIL';

export const UPDATE_BANNER = 'UPDATE_BANNER';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAIL = 'UPDATE_BANNER_FAIL';

export const GET_ESPS = 'GET_ESPS';
export const GET_ESPS_SUCCESS = 'GET_ESPS_SUCCESS';
export const GET_ESPS_FAIL = 'GET_ESPS_FAIL';

export const CLEAR_BANNER = 'CLEAR_BANNER';

export const RESET_IMAGES = 'RESET_IMAGES';

export const SET_LOADING_BANNER = 'SET_LOADING_BANNER';

export const SET_MODAL = 'SET_MODAL';

// Upload FIle
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const UPLOAD_FILE_LANDING = 'UPLOAD_FILE_LANDING';
export const UPLOAD_FILE_LANDING_SUCCESS = 'UPLOAD_FILE_LANDING_SUCCESS';
export const UPLOAD_FILE_LANDING_FAIL = 'UPLOAD_FILE_LANDING_FAIL';

export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL';

export const UPDATE_BANNER_VALUES = 'UPDATE_BANNER_VALUES';
