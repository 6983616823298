import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer, ListItem, List, ListItemText, Collapse, ListItemIcon, Grid,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import CreateBannerModal from '../CreateBannerModal/CreateBannerModal';
import Avatar from '../Avatar/Avatar';
import { clearWidget } from '../../state/modules/widget/actions';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  logo: {
    display: 'flex',
    'justify-content': 'center',
    padding: '15px',
    '& img': {
      width: '70%',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(8),
    paddingTop: 0,
    paddingBottom: 0,
  },
  list: {
    marginTop: 50,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    paddingLeft: '48px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemText: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  subList: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  listItemIcon: {
    minWidth: 15,
  },
  subListItemTextActive: {
    fontSize: 12,
    color: '#000',
    fontWeight: 'bold',
  },
  subListItemText: {
    fontSize: 12,
    color: '#b5b4b9',
    fontWeight: 'bold',
  },
}));

export default function Sidebar({ history }) {
  const classes = useStyles();
  const [open, setOpen] = useState({
    banners: true,
    widgets: true,
    statistics: true,
  });
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleClick = (menu) => {
    setOpen({ ...open, [menu]: !open[menu] });
  };

  const handleCreateWidget = () => {
    dispatch(clearWidget())
      .then(() => {
        history.push('/widgets/create');
      });
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <Link className={classes.logo} to="/">
          <img src="/logo_mowmedia.png" alt="logo" style={{ height: '100%' }} />
        </Link>
      </div>

      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.list}
      >
        <ListItem className={classes.listItem} button onClick={() => handleClick('banners')}>
          <ListItemText
            primaryTypographyProps={{
              className: classes.listItemText,
            }}
            primary="BANNERS"
          />
          {open.banners ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.banners} timeout="auto" unmountOnExit>
          <List component="div" className={classes.subList}>
            <ListItem
              button
              className={classes.nested}
              onClick={handleOpenModal}
              // component={props => (<Link to="/banners/create" {...props} />)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AddIcon style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }} />
              </ListItemIcon>
              <ListItemText
                primary="Create new"
                primaryTypographyProps={{
                  className: useLocation().pathname.search(/\/banners\/create/) !== -1 ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push('/banners')}
            >
              <ListItemText
                primary="List All"
                primaryTypographyProps={{
                  className: useLocation().pathname === '/banners' ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem className={classes.listItem} button onClick={() => handleClick('widgets')}>
          <ListItemText
            primaryTypographyProps={{
              className: classes.listItemText,
            }}
            primary="WIDGETS"
          />
          {open.widgets ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.widgets} timeout="auto" unmountOnExit>
          <List component="div" className={classes.subList}>
            <ListItem
              button
              className={classes.nested}
              onClick={handleCreateWidget}
              // component={props => (<Link to="/banners/create" {...props} />)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AddIcon style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }} />
              </ListItemIcon>
              <ListItemText
                primary="Create new"
                primaryTypographyProps={{
                  className: useLocation().pathname.search(/\/widgets\/create/) !== -1 ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push('/widgets')}
            >
              <ListItemText
                primary="List All"
                primaryTypographyProps={{
                  className: useLocation().pathname === '/widgets' ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem className={classes.listItem} button onClick={() => handleClick('widgets')}>
          <ListItemText
            primaryTypographyProps={{
              className: classes.listItemText,
            }}
            primary="STATISTICS"
          />
          {open.statistics ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.statistics} timeout="auto" unmountOnExit>
          <List component="div" className={classes.subList}>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push('/statistic/widgets')}
            >
              <ListItemText
                primary="Publisher"
                primaryTypographyProps={{
                  className: useLocation().pathname === '/statistic/widgets' ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push('/statistic/banners')}
            >
              <ListItemText
                primary="Advertiser"
                primaryTypographyProps={{
                  className: useLocation().pathname === '/statistic/banners' ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push('/statistic/iHeartMedia')}
            >
              <ListItemText
                primary="iHeartMedia"
                primaryTypographyProps={{
                  className: useLocation().pathname === '/statistic/iHeartMedia' ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push('/statistic/iHeartMedia/plays')}
            >
              <ListItemText
                primary="Plays"
                primaryTypographyProps={{
                  className: useLocation().pathname === '/statistic/iHeartMedia/plays' ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push('/statistic/player-stats')}
            >
              <ListItemText
                primary="Player Stats"
                primaryTypographyProps={{
                  className: useLocation().pathname === '/statistic/player-stats' ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem className={classes.listItem} button onClick={() => handleClick('podcasts')}>
          <ListItemText
            primaryTypographyProps={{
              className: classes.listItemText,
            }}
            primary="Podcasts"
          />
          {open.statistics ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.statistics} timeout="auto" unmountOnExit>
          <List component="div" className={classes.subList}>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push('/podcasts/search')}
            >
              <ListItemText
                primary="Search"
                primaryTypographyProps={{
                  className: useLocation().pathname === '/podcasts/search' ? classes.subListItemTextActive : classes.subListItemText,
                }}
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
      <Grid container item md={12} direction="row" justify="center" alignItems="flex-end">
        <Grid container item md={12} direction="row" justify="center" alignItems="center" style={{ marginBottom: 30 }}>
          <Avatar />
        </Grid>
      </Grid>
      <CreateBannerModal
        openDialog={openModal}
        handleClose={setOpenModal}
        history={history}
      />
    </Drawer>
  );
}
