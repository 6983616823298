import * as actionTypes from './types';

let initialState = {
  podcasts: [],
  loading: false,
};

function reducers(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.GET_PODCASTS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PODCASTS_SUCCESS:
      return {
        ...state,
        podcasts: action.payload,
        loading: false,
      };
    case actionTypes.GET_PODCASTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default reducers;
