import axios from 'axios';
import { toast } from 'react-toastify';
import * as actionTypes from './types';
import { getHeaders } from '../../../utils/utils';

export function addWidgetAction(formData) {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      const res = await axios.post('/api/widgets/', { ...formData }, { headers });
      toast.success('Widget Saved!');
      dispatch({ type: actionTypes.ADD_WIDGET_SUCCESS, payload: res.data });
    } catch (error) {
      const { errors } = error.response.data;
      Object.entries(errors).forEach(item => toast.error(item[1].msg));
      console.error(error);
    }
  };
}

export function getWidgetsAction(params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_WIDGETS });
      const headers = getHeaders();
      const res = await axios.get('/api/widgets', { params, headers });
      dispatch({ type: actionTypes.GET_WIDGETS_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.GET_WIDGETS_FAIL, payload: error.response });
    }
  };
}

export function getWidgetAction(id, params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_WIDGET });
      const headers = getHeaders();
      const res = await axios.get(`/api/widgets/${id}`, { params, headers });
      dispatch({ type: actionTypes.GET_WIDGET_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateWidgetAction(data, id) {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      const res = await axios.put(`/api/widgets/${id}`, data, { headers });
      toast.success('Advertiser Updated!');
      dispatch({ type: actionTypes.UPDATE_WIDGET_SUCCESS, payload: res.data });
    } catch (error) {
      const { errors } = error.response.data;
      Object.entries(errors).forEach(item => toast.error(item[1].msg));
      console.error(error);
      console.log(error);
    }
  };
}

export function deleteWidgetAction(id) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.delete(`/api/widgets/${id}`, { headers });
    } catch (error) {
      console.log(error);
    }
  };
}

export function clearWidget() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.CLEAR_WIDGET });
    } catch (error) {
      console.log(error);
    }
  };
}
