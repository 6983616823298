export const ADD_WIDGET = 'ADD_WIDGET';
export const ADD_WIDGET_SUCCESS = 'ADD_WIDGET_SUCCESS';
export const ADD_WIDGET_FAIL = 'ADD_WIDGET_FAIL';

export const GET_WIDGETS = 'GET_WIDGETS';
export const GET_WIDGETS_SUCCESS = 'GET_WIDGETS_SUCCESS';
export const GET_WIDGETS_FAIL = 'GET_WIDGETS_FAIL';

export const GET_WIDGET = 'GET_WIDGET';
export const GET_WIDGET_SUCCESS = 'GET_WIDGET_SUCCESS';
export const GET_WIDGET_FAIL = 'GET_WIDGET_FAIL';

export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const UPDATE_WIDGET_SUCCESS = 'UPDATE_WIDGET_SUCCESS';
export const UPDATE_WIDGET_FAIL = 'UPDATE_WIDGET_FAIL';

export const CLEAR_WIDGET = 'CLEAR_WIDGET';
