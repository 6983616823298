import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';

const CreateNewBanner = React.lazy(() => import('./Banners/CreateNewBanner'));
const ListBanners = React.lazy(() => import('./Banners/ListBanners'));
const CreateWidget = React.lazy(() => import('./Widgets/CreateWidget'));
const ListWidgets = React.lazy(() => import('./Widgets/ListWidgets'));
const Statistic = React.lazy(() => import('./Statistics/Statistic'));
const iHMStats = React.lazy(() => import('./Statistics/IheartMediaStats'));
const SearchPodcast = React.lazy(() => import('./SearchPodcast/SearchPodcast'));
const iHMPLays = React.lazy(() => import('./Statistics/iHMPlays'));
const PlayerStats = React.lazy(() => import('./Statistics/PlayerStats'));

class Main extends React.Component {
  render() {
    const { location } = this.props;

    return (
      <Fragment>
        <Sidebar history={this.props.history} location={location} />
        <div style={{ marginLeft: '294px' }}>
          <Switch>
            <Route path="/banners/edit/:id/:step" component={CreateNewBanner} />
            <Route path="/banners/create/:step" component={CreateNewBanner} />
            <Route path="/banners" component={ListBanners} />
            <Route path="/widgets/create" component={CreateWidget} />
            <Route path="/widgets/edit/:id" component={CreateWidget} />
            <Route path="/widgets" component={ListWidgets} />
            <Route path="/statistic/widgets" key="wqeqwdasd" component={Statistic} />
            <Route path="/statistic/banners" key="bnvbnvbn" component={Statistic} />
            <Route path="/statistic/iHeartMedia/plays" key="iHeartMediaPlays" component={iHMPLays} />
            <Route path="/statistic/iHeartMedia" key="iHeartMedia" component={iHMStats} />
            <Route path="/statistic/player-stats" key="player-stats" component={PlayerStats} />
            <Route path="/podcasts/search" component={SearchPodcast} />
          </Switch>
        </div>
      </Fragment>
    );
  }
}

export default Main;
