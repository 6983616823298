import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  modal: {
    width: '405px',
    borderRadius: '35px',
  },
  modalDescription: {
    fontSize: '38px',
    color: '#3fb7e9',
    fontFamily: 'GothamProBlack',
    fontWeight: '900',
  },
  writeHand: {
    fontSize: '38px',
    fontWeight: '400',
    fontFamily: 'apple color emoji,segoe ui emoji,noto color emoji,android emoji,emojisymbols,emojione mozilla,twemoji mozilla,segoe ui symbol',
  },
  button: {
    marginBottom: '15px',
    padding: '10px 0',
    width: '203px',
    textTransform: 'none',
  },
  modalSubDesc: {
    fontFamily: 'GothamProBlack',
    fontWeight: '900',
    padding: '30px 0',
  },
});
