import axios from 'axios';
import * as actionTypes from './types';
import { getHeaders } from '../../../utils/utils';

export function getPodcasts(params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_PODCASTS });
      const headers = getHeaders();
      const res = await axios.get('/api/podcasts', { params, headers });
      dispatch({ type: actionTypes.GET_PODCASTS_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.GET_PODCASTS_FAIL, payload: error.response });
    }
  };
}
