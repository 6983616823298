import * as actionTypes from './types';

let initialState = {
  banners: [],
  total: 0,
  banner: null,
  esps: [],
  backgroundImage: {},
  crossImage: {},
  logo: {},
  loadingList: true,
  loadingBanner: true,
  createdModal: false,
};

function reducers(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.GET_BANNERS:
      return {
        ...state,
        loadingList: true,
      };
    case actionTypes.ADD_BANNER_SUCCESS:
      return {
        ...state,
        banner: action.payload,
        loadingBanner: false,
      };
    case actionTypes.GET_BANNER:
      return {
        ...state,
        loadingBanner: true,
      };
    case actionTypes.GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload.docs,
        total: action.payload.total,
        loadingList: false,
      };
    case actionTypes.GET_BANNERS_FAIL:
      return {
        ...state,
        loadingList: false,
      };
    case actionTypes.GET_BANNER_SUCCESS: {
      const banner = action.payload;
      return {
        ...state,
        banner,
        loadingBanner: false,
      };
    }
    case actionTypes.UPDATE_BANNER_SUCCESS: {
      const banner = action.payload;
      banner.steps.five.isActiveUrlButton = true;
      return {
        ...state,
        banner,
        loadingBanner: false,
      };
    }
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return { ...state, [action.field]: action.payload };
    case actionTypes.UPLOAD_FILE_LANDING_SUCCESS:
      const { field } = action;
      return {
        ...state,
        banner: {
          ...state.banner,
          steps: {
            ...state.banner.steps,
            five: {
              ...state.banner.steps.five,
              [field]: action.payload,
            },
          },
        },
      };
    case actionTypes.UPDATE_BANNER_VALUES: {
      const banner = { ...state.banner };
      const { bannerData, step } = action.payload;
      banner.steps[step] = bannerData;
      return {
        ...state,
        banner: {
          ...banner,
        },
      };
    }
    case actionTypes.DELETE_FILE_SUCCESS:
      return { ...state, [action.field]: {} };
    case actionTypes.CLEAR_BANNER:
      return {
        ...state,
        banner: null,
        backgroundImage: {},
        crossImage: {},
        logo: {},
        loadingBanner: true,
      };
    case actionTypes.RESET_IMAGES:
      return {
        ...state,
        backgroundImage: {},
        crossImage: {},
        logo: {},
      };
    case actionTypes.SET_LOADING_BANNER:
      return {
        ...state,
        loadingBanner: action.payload,
      };
    case actionTypes.SET_MODAL:
      return {
        ...state,
        createdModal: action.payload,
      };
    case actionTypes.GET_ESPS_SUCCESS:
      return {
        ...state,
        esps: action.payload,
      };
    default:
      return state;
  }
}

export default reducers;
