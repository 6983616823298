import * as actionTypes from './types';

let initialState = {
  widgets: [],
  total: 0,
  widget: null,
  loadingList: true,
  loadingWidget: true,
};

function reducers(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.GET_WIDGETS:
      return {
        ...state,
        loadingList: true,
      };
    case actionTypes.GET_WIDGET:
      return {
        ...state,
        loadingWidget: true,
      };
    case actionTypes.ADD_WIDGET_SUCCESS:
      return {
        ...state,
        widget: action.payload,
      };
    case actionTypes.GET_WIDGETS_SUCCESS:
      return {
        ...state,
        widgets: action.payload.docs,
        total: action.payload.total,
        loadingList: false,
      };
    case actionTypes.GET_WIDGETS_FAIL:
      return {
        ...state,
        loadingList: false,
      };
    case actionTypes.GET_WIDGET_SUCCESS:
    case actionTypes.UPDATE_WIDGET_SUCCESS:
      return {
        ...state,
        widget: action.payload,
        loadingWidget: false,
      };
    case actionTypes.CLEAR_WIDGET:
      return { widget: null };
    default:
      return state;
  }
}

export default reducers;
