import axios from 'axios';
import * as actionTypes from './types';

export function getBannerAction(id, subId, widgetId) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_ADBOX });
      const res = await axios.get(`/api/adBox/${id}`, { params: { subId, widgetId } });
      dispatch({ type: actionTypes.GET_ADBOX_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
}

export function checkUserByEmail({
  id, email, subId, data, widgetId, onlyThreeScreen,
}) {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/api/adBox/${id}/iheartmedia-status`, {
        email, subId, widgetId, onlyThreeScreen, ...data,
      });
      dispatch({ type: actionTypes.SET_NEXT_STEP, payload: res.data });
      return res.data.url;
    } catch (error) {
      console.error(error.response);
      dispatch({ type: actionTypes.SET_NEXT_STEP_ERROR, payload: error.response.data });
      return false;
    }
  };
}

export function createAccountIntoIHeartMedia({ id, ...data }) {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/api/adBox/${id}/iheartmedia-sign-up`, data);
      dispatch({ type: actionTypes.SET_NEXT_STEP, payload: res.data });
      return res.data.url;
    } catch (error) {
      console.error(error);
      dispatch({ type: actionTypes.SET_NEXT_STEP_ERROR, payload: error.response.data });
      return false;
    }
  };
}

export function setTotalNewTabLaunches(id, subId, widgetId) {
  return async () => {
    try {
      await axios.post(`/api/adBox/${id}/new-tab-launches`, { subId, widgetId });
    } catch (error) {
      console.error(error);
    }
  };
}

export const playPodcast = () => ({
  type: actionTypes.LISTEN_TO_PODCAST,
});
