import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PublicRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = rest.user.isAuthenticated;

  return (
    <Route
      {...rest}
      render={props => (!isLoggedIn
        ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ))
      }
    />
  );
};
const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(PublicRoute);
