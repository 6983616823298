import axios from 'axios';
import { toast } from 'react-toastify';
import * as actionTypes from './types';
import { getHeaders } from '../../../utils/utils';

export function addBannerAction(formData, step) {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      const res = await axios.post('/api/banners/', { ...formData, step }, { headers });
      toast.success(`Step ${step} Saved!`);
      dispatch({ type: actionTypes.ADD_BANNER_SUCCESS, payload: res.data });
    } catch (error) {
      const { errors } = error.response.data;
      Object.entries(errors).forEach(item => toast.error(item[1].msg));
      console.error(error);
      throw (error);
    }
  };
}

export function getBannersAction(params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_BANNERS });
      const headers = getHeaders();
      const res = await axios.get('/api/banners', { params, headers });
      dispatch({ type: actionTypes.GET_BANNERS_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.GET_BANNERS_FAIL, payload: error.response });
    }
  };
}

export function getBannerAction(id, params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_BANNER });
      const headers = getHeaders();
      const res = await axios.get(`/api/banners/${id}`, { params, headers });
      dispatch({ type: actionTypes.GET_BANNER_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getESPs() {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      const res = await axios.get('/api/banners/esps', { headers });
      dispatch({ type: actionTypes.GET_ESPS_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateBannerAction(data, id, step) {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      const res = await axios.put(`/api/banners/${id}`, { ...data, step }, { headers });
      toast.success('Advertiser Updated!');
      dispatch({ type: actionTypes.UPDATE_BANNER_SUCCESS, payload: res.data });
    } catch (error) {
      const { errors } = error.response.data;
      if (errors) {
        // TODO fix on back end side object errors don't added in response
        Object.entries(errors).forEach(item => toast.error(item[1].msg));
      }
      throw (error);
    }
  };
}

export function deleteBannerAction(id) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.delete(`/api/banners/${id}`, { headers });
    } catch (error) {
      console.log(error);
    }
  };
}

export function setLoadingBanner(value) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SET_LOADING_BANNER, payload: value });
    } catch (error) {
      // TODO show in UI
      console.log(error);
    }
  };
}

export function uploadFileStepFive(formData, field) {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      headers['Content-Type'] = 'multipart/form-data';
      const res = await axios.post('/api/files/upload', formData, { headers });
      dispatch({
        type: actionTypes.UPLOAD_FILE_LANDING_SUCCESS,
        payload: res.data,
        field,
      });
    } catch (error) {
      // TODO show in UI
      console.log(error);
    }
  };
}

export const updateBannerValues = (bannerData, step) => ({
  type: actionTypes.UPDATE_BANNER_VALUES,
  payload: { bannerData, step },
});

export function uploadFile(formData, field) {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      headers['Content-Type'] = 'multipart/form-data';
      const res = await axios.post('/api/files/upload', formData, { headers });
      dispatch({ type: actionTypes.UPLOAD_FILE_SUCCESS, payload: res.data, field });
    } catch (error) {
      // TODO show in UI
      console.error(error);
    }
  };
}

export function deleteFile(field) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.DELETE_FILE_SUCCESS, field });
    } catch (error) {
      // TODO show in UI
      console.log(error);
    }
  };
}

export function clearBanner() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.CLEAR_BANNER });
    } catch (error) {
      // TODO show in UI
      console.log(error);
    }
  };
}

export function resetImages() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.RESET_IMAGES });
    } catch (error) {
      // TODO show in UI
      console.log(error);
    }
  };
}

export function setModal(value) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SET_MODAL, payload: value });
    } catch (error) {
      // TODO show in UI
      console.log(error);
    }
  };
}
