import axios from 'axios';
import * as actionTypes from './types';

export function getBannerAction(id, subId, widgetId, type) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/static-banner/${id}`, { params: { subId, widgetId, type } });
      dispatch({ type: actionTypes.GET_STATIC_BANNER_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
}

export function checkUserByEmail({
  id, email, subId, data, widgetId,
}) {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/api/adBox/${id}/iheartmedia-status`, {
        email, subId, widgetId, ...data,
      });
      dispatch({ type: actionTypes.SET_STATIC_BANNER_NEXT_STEP, payload: res.data });
      return res.data.url;
    } catch (error) {
      console.error(error);
      dispatch({ type: actionTypes.SET_STATIC_BANNER_NEXT_STEP_ERROR, payload: error.response.data });
      return false;
    }
  };
}

export function createAccountIntoIHeartMedia({ id, ...data }) {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/api/adBox/${id}/iheartmedia-sign-up`, data);
      dispatch({ type: actionTypes.SET_STATIC_BANNER_NEXT_STEP, payload: res.data });
      return res.data.url;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
}

export function setTotalPlays({ bannerId, ...rest }) {
  return async () => {
    try {
      await axios.post(`/api/adBox/${bannerId}/total-plays`, rest);
    } catch (error) {
      console.error(error);
    }
  };
}


export function recordPodcastPlayingTime({ bannerId, ...rest }) {
  return async () => {
    try {
      await axios.get(`/api/adBox/${bannerId}/record-podcast-playing-time`, { params: rest });
    } catch (error) {
      console.error(error);
    }
  };
}

export function getStreamLink(source) {
  return async () => {
    try {
      const result = await axios.get('/api/static-banner/stream-link', { params: { source } });
      return result.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
}
