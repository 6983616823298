import * as actionTypes from './types';

let initialState = {
  banner: null,
  nextStep: 'one',
  url: '',
  error: '',
};

function reducers(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.GET_STATIC_BANNER_SUCCESS:
      return {
        ...state,
        banner: action.payload,
      };
    case actionTypes.SET_STATIC_BANNER_NEXT_STEP:
      return {
        ...state,
        nextStep: action.payload.nextStep,
        url: action.payload.url || '',
        error: '',
      };
    case actionTypes.SET_STATIC_BANNER_NEXT_STEP_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };
    default:
      return state;
  }
}

export default reducers;
