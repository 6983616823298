import axios from 'axios';
import * as actionTypes from './types';
import { getHeaders } from '../../../utils/utils';

export function getStatistic(params, publisherId = false) {
  return async (dispatch) => {
    try {
      if (publisherId) {
        dispatch({ type: actionTypes.GET_STATISTIC_SUBTABLE, payload: { publisherId } });
      } else {
        dispatch({ type: actionTypes.GET_STATISTIC_BANNER });
      }
      const headers = getHeaders();
      const res = await axios.get('/api/statistics/banners', { params, headers });
      if (publisherId) {
        dispatch({ type: actionTypes.GET_STATISTIC_SUBTABLE_SUCCESS, payload: res.data });
      } else {
        dispatch({ type: actionTypes.GET_STATISTIC_BANNER_SUCCESS, payload: res.data });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.GET_STATISTIC_FAIL, payload: error.response });
    }
  };
}

export function getStatisticForPlayer(params, publisherId = false) {
  return async (dispatch) => {
    try {
      if (publisherId) {
        dispatch({ type: actionTypes.GET_STATISTIC_SUBTABLE, payload: { publisherId } });
      } else {
        dispatch({ type: actionTypes.GET_STATISTIC_BANNER });
      }
      const headers = getHeaders();
      const res = await axios.get('/api/statistics/podcast-player-stats', { params, headers });
      if (publisherId) {
        dispatch({ type: actionTypes.GET_STATISTIC_SUBTABLE_SUCCESS, payload: res.data });
      } else {
        dispatch({ type: actionTypes.GET_STATISTIC_BANNER_SUCCESS, payload: res.data });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.GET_STATISTIC_FAIL, payload: error.response });
    }
  };
}

export function getiHeartMediaStatistic(dates) {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      const res = await axios.get('/api/statistics/ihm', { params: dates, headers });
      dispatch({ type: actionTypes.GET_STATISTIC_IHM_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getiHeartMediaPlaysStats(dates) {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      const res = await axios.get('/api/statistics/plays', { params: dates, headers });
      dispatch({ type: actionTypes.GET_IHM_PLAYS_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getFilter(params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_FILTER });
      const headers = getHeaders();
      const res = await axios.get('/api/statistics/filter', { params, headers });
      dispatch({ type: actionTypes.GET_FILTER_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.GET_FILTER_FAIL, payload: error.response });
    }
  };
}

export function clearData() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.CLEAR_DATA });
  };
}
