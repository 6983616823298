import React from 'react';
import {
  Typography,
  Dialog,
  DialogContent,
  Grid,
  Button,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import get from 'lodash/get';

import { useStyles } from './styled';

export default function CreateBannerModal({
  openDialog,
  handleClose,
  history,
  editBanner,
}) {
  const classes = useStyles();

  const goToStep = (step) => {
    handleClose(false);
    let path = `/banners/create/${step}`;
    if (editBanner && editBanner._id) {
      path = `/banners/edit/${editBanner._id}/${step}`;
    }
    history.push(path);
  };

  const isFinished = (step) => {
    if (editBanner) {
      return get(editBanner, `steps[${step}].finished`, false);
    }
    return false;
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => handleClose(false)}
      classes={{
        paperWidthSm: classes.modal,
      }}
    >
      <DialogTitle disableTypography>
        <Grid item md={12} container justify="flex-end" alignItems="center">
          <IconButton aria-label="close" onClick={() => handleClose(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" item md={12} spacing={6} justify="center" alignItems="center">
          <span role="img" aria-label="write hand" className={classes.writeHand}>✍️</span>
        </Grid>
        <Grid container direction="column" item md={12} spacing={6} justify="center" alignItems="center">
          <Typography className={classes.modalDescription}>
            LET’S
            {' '}
            { editBanner ? 'EDIT' : 'CREATE' }
          </Typography>
          <Typography className={classes.modalDescription}>
            {editBanner ? 'THE' : 'A NEW' }
            {' '}
            AD
          </Typography>
        </Grid>
        <Grid container direction="column" item md={12} spacing={6} justify="center" alignItems="center">
          <Typography className={classes.modalSubDesc}>Please select the screen</Typography>
        </Grid>
        <Grid container direction="column" item md={12} spacing={6} justify="center" alignItems="center">
          <Button variant="contained" color="primary" className={classes.button} onClick={() => goToStep('one')}>Screen one</Button>
        </Grid>
        <Grid container direction="column" item md={12} spacing={6} justify="center" alignItems="center">
          <Button variant="contained" color="primary" disabled={!isFinished('one')} className={classes.button} onClick={() => goToStep('two')}>Screen two</Button>
        </Grid>
        <Grid container direction="column" item md={12} spacing={6} justify="center" alignItems="center">
          <Button variant="contained" color="primary" disabled={!isFinished('two')} className={classes.button} onClick={() => goToStep('three')}>Screen three</Button>
        </Grid>
        <Grid container direction="column" item md={12} spacing={6} justify="center" alignItems="center">
          <Button variant="contained" color="primary" disabled={!isFinished('three')} className={classes.button} onClick={() => goToStep('four')}>Screen four</Button>
        </Grid>

        <Grid container direction="column" item md={12} spacing={6} justify="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => goToStep('five')}
            disabled={!isFinished('one')}
          >
            Screen Five
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
