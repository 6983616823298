import * as actionTypes from './types';

let initialState = {
  isAuthenticated: null,
};

function reducers(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        ...action.payload.user,
      };
    }
    case actionTypes.GET_USER_SUCCESS:
      return {
        isAuthenticated: true,
        ...action.payload,
      };
    case actionTypes.GET_USER_FAIL:
    case actionTypes.LOGOUT_SUCCESS:
      return {
        isAuthenticated: false,
      };
    case actionTypes.USER_LOADING:
      return {
        isAuthenticated: null,
      };
    default:
      return state;
  }
}

export default reducers;
