import React, { Fragment } from 'react';
import {
  Avatar as MuiAvatar, Typography, makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { deepOrange } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  userName: {
    marginRight: '10px',
    fontWeight: 'bold',
  },
}));

export default function Avatar() {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  return (
    <Fragment>
      <Typography className={classes.userName}>
        {`Hey ${user.name}!`}
      </Typography>
      <MuiAvatar className={classes.orange}>A</MuiAvatar>
    </Fragment>
  );
}
