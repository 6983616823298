import { createMuiTheme } from '@material-ui/core/styles';
import * as Colors from '../constants/colors';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  spacing: 8,
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#fff',
      },
    },
    MuiFilledInput: {
      input: {
        paddingLeft: '30px',
        paddingRight: '30px',
        color: Colors.DARK_BLUE,
      },
      adornedEnd: {
        paddingRight: 0,
      },
      root: {
        backgroundColor: '#fff',
        height: '100%',
        border: 'none',
        borderRadius: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        '&:hover, &:focus': {
          backgroundColor: '#fff',
        },
        '&$error': {
          '&:before': {
            display: 'block',
            borderBottom: '4px solid transparent !important',
          },
          '&:after': {
            display: 'block',
            borderBottom: `4px solid ${Colors.RED} !important`,
          },
        },
      },

      underline: {
        '&:before': {
          borderBottom: 'transparent',
        },
        '&:after': {
          borderBottom: 'transparent',
        },
        '&:hover': {
          '&:before': {
            borderBottom: 'transparent !important',
          },
        },
      },
    },
    MuiInput: {
      root: {
        '&$error': {
          '&:after': {
            borderBottom: '4px solid #E23166 !important',
          },
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 4,
        margin: 4,
      },
      label: {
        fontSize: 15,
        lineHeight: '32px',
        letterSpacing: '-0.04em',
      },
      deleteIcon: {
        margin: '0 16px 0 0',
      },
    },
    MuiTabs: {
      root: {
        width: '100%',
      },
      flexContainer: {
        // height: 64,
      },
      indicator: {
        left: '0',
        width: '4px',
        backgroundImage: 'linear-gradient(to right, #48b9e8 -98%, #4a90e2 100%)',
      },
    },

    MuiTab: {
      root: {
        minWidth: '0 !important',
        '&$selected': {
          color: '#171718 !important',
          'font-weight': 600,
        },
      },
      textColorInherit: {
        color: '#171718',
      },
      wrapper: {
        width: 'auto',
      },
    },
    MuiInputAdornment: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        paddingRight: '30px',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'AvNext',
        letterSpacing: '-0.04em',
        '&$error': {
          color: Colors.LIGHT_BLUE,
        },
      },
    },
    MuiTypography: {
      body2: {
        fontFamily: 'AvNext',
        letterSpacing: '-0.04em',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
      },
      outlined: {
        backgroundColor: Colors.LIGHT_BLUE,
        border: '1px solid #F1F1FF',
        color: '#fff',
        minWidth: '200px',
        '& span': {
          fontFamily: 'AvBold',
        },
        '&:hover': {
          backgroundColor: '#43bae9',
          '& span': {
            color: '#fff',
          },
        },
      },

      contained: {
        color: '#000',
        fontSize: '16px',
        boxShadow: 'none',
        fontWeight: 'bold',
        borderRadius: '50px',
        padding: '10px 50px',
        backgroundColor: 'none',
      },
      containedPrimary: {
        color: '#fff',
        backgroundColor: '#3648af',
        borderRadius: '50px',
        padding: '10px 25px',
        '&:hover': {
          backgroundColor: '#3648af',
        },
      },
      sizeSmall: {
        minWidth: 'inherit',
        color: Colors.BLUE,
        backgroundColor: 'transparent',
        fontWeight: '100',
        '& span': {
          fontFamily: 'AvNext',
        },
        '&:hover': {
          backgroundColor: Colors.BLUE,
        },
      },
    },
    MuiInputLabel: {
      filled: {
        color: '#A4A5C6',
        transform: 'translate(30px, 22px) scale(1)',
        '&$shrink': {
          transform: 'translate(30px, 10px) scale(0.75)',
        },
      },
      root: {
        '&$focused': {
          color: Colors.LIGHT_BLUE,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(241, 241, 255, .94)',
      },
    },
    MuiDialog: {
      paperWidthSm: {
        width: '730px',
        maxWidth: '730px',
        boxShadow: 'none',
      },
    },
    MuiGrid: {
      'spacing-xs-4': {
        margin: 0,
      },
      'spacing-xs-6': {
        margin: 0,
      },
      'spacing-xs-8': {
        margin: 0,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '22px solid #f4f4fa',
        padding: '5px 16px',
        // '&:first-child': {
        //   borderRadius: '50% 0 0 50%',
        // },
        // '&:last-child': {
        //   borderRadius: '0 50% 50% 0',
        // },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '30px',
      },
      input: { padding: '14.5px 14px' },
    },
  },
});

theme.spacing(8);

export default theme;
