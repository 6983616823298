import moment from 'moment';

export const getHeaders = () => {
  const authToken = `${localStorage.getItem('auth_token')}`;
  return {
    JWTAuthorization: authToken || '',
  };
};


export const sortByUpdateDate = (arr) => {
  let newArr = [...arr];
  return newArr.sort((elemA, elemB) => {
    if (moment(elemA.updatedAt).isBefore(moment(elemB.updatedAt))) {
      return 1;
    }
    if (moment(elemA.updatedAt).isAfter(moment(elemB.updatedAt))) {
      return -1;
    }
    return 0;
  });
};

export const compareObjects = (elemA, elemB) => JSON.stringify(elemA) === JSON.stringify(elemB);

export const cloneObject = obj => JSON.parse(JSON.stringify(obj));

export const getHost = () => `${window.location.protocol}//${window.location.hostname}`;

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const validateEmail = (email) => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
};

export const validateName = name => name.length >= 3;

export const checkResolution = (file, validateOptions) => new Promise((resolve, reject) => {
  const img = new Image();
  img.addEventListener('load', function () {
    const aspectRatio = 15 / 17;
    let defaultHeight = 682;
    let defaultWidth = 600;

    if (validateOptions) {
      defaultHeight = validateOptions.height;
      defaultWidth = validateOptions.width;
      if (this.height <= defaultHeight && this.width <= defaultWidth) {
        return resolve();
      }
      return reject();
    }
    if (this.height >= defaultHeight && this.width >= defaultWidth && Math.abs(this.width / this.height - aspectRatio) < 0.01) {
      return resolve();
    }
    return reject();
  });
  img.src = URL.createObjectURL(file);
});

export const _hasPopupBlocker = (poppedWindow) => {
  let result = false;
  try {
    if (typeof poppedWindow === 'undefined') {
      // Safari with popup blocker... leaves the popup window handle undefined
      result = true;
    } else if (poppedWindow && !poppedWindow.closed) {
      // This happens if the user opens and closes the client window...
      // Confusing because the handle is still available, but it's in a "closed" state.
      // We're not saying that the window is not being blocked, we're just saying
      // that the window has been closed before the test could be run.
      result = false;
    } else if (poppedWindow && poppedWindow.test) {
      // This is the actual test. The client window should be fine.
      result = false;
    } else {
      // Else we'll assume the window is not OK
      result = true;
    }
  } catch (err) {
    // if (console) {
    //    console.warn("Could not access popup window", err);
    // }
  }
  return result;
};
