export const GET_STATISTIC_WIDGETS = 'GET_STATISTIC_WIDGETS';
export const GET_STATISTIC_WIDGETS_SUCCESS = 'GET_STATISTIC_WIDGETS_SUCCESS';

export const GET_FILTER = 'GET_FILTER';
export const GET_FILTER_SUCCESS = 'GET_FILTER_SUCCESS';
export const GET_FILTER_FAIL = 'GET_FILTER_FAIL';

export const GET_STATISTIC_BANNER = 'GET_STATISTIC_BANNER';
export const GET_STATISTIC_BANNER_SUCCESS = 'GET_STATISTIC_BANNER_SUCCESS';

export const GET_STATISTIC_FAIL = 'GET_STATISTIC_FAIL';

export const GET_STATISTIC_SUBTABLE = 'GET_STATISTIC_SUBTABLE';
export const GET_STATISTIC_SUBTABLE_SUCCESS = 'GET_STATISTIC_SUBTABLE_SUCCESS';

export const GET_STATISTIC_IHM_SUCCESS = 'GET_STATISTIC_IHM_SUCCESS';

export const GET_IHM_PLAYS_SUCCESS = 'GET_IHM_PLAYS_SUCCESS';

export const CLEAR_DATA = 'CLEAR_DATA';
