export const DARK_BLUE = '#484A6F';
export const ULTRA_LIGHT_BLUE = '#F1F1FF';
export const LIGHT_BLUE = '#43bae9';
export const BLUE = '#43bae9';
export const WHITE = '#E5E5E5';
export const RED = '#E23166';
// export const ORANGE = '#F8941E';
export const GREEN = '#1BD687';
export const BODY_BG = '#f4f5fe';

export const DEFAULT_BG_IMAGE = '/placeholder-image.png';
