import React, { Suspense, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Main from './Main';
import { getUser } from '../state/modules/user/actions';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import PublicRoute from '../components/PublicRoute/PublicRoute';

const AdBox = React.lazy(() => import('./AdBox/AdBox'));
const StaticBanner = React.lazy(() => import('./StaticBanner/StaticBanner'));
const LoginPage = React.lazy(() => import('./LoginPage/LoginPage'));

export default function Root({ location }) {
  const dispatch = useDispatch();
  const { isAuthenticated = null } = useSelector(state => state.user);
  useEffect(() => {
    if (!location.pathname.includes('/adBox') && !location.pathname.includes('/staticBanner')) {
      dispatch(getUser());
    }
  }, []);

  if (!location.pathname.includes('/adBox') && !location.pathname.includes('/staticBanner') && isAuthenticated === null) {
    return <div className="loading">Loading&#8230;</div>;
  }

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to="/banners" />;
  }

  return (
    <Suspense
      fallback={<div>Loading...</div>}
    >
      <Switch>
        <PublicRoute path="/adBox/:id" component={AdBox} />
        <PublicRoute path="/staticBanner/:id" component={StaticBanner} />
        <PublicRoute path="/sign-in" component={LoginPage} />
        <PrivateRoute path="/" component={Main} />
      </Switch>
    </Suspense>
  );
}
